<template>
  <section class="product">

    <app-breastfeeding-banner />

    <div class="container text-center py-8">
      <div class="text-primary font-display font-bold text-5xl mb-4">{{ product.text.name }}</div>
      <div class="text-primary font-extrabold text-2xl">{{ product.text.age }}</div>
      <div class="text-primary font-bold text-xl mb-6">{{ product.text.description }}</div>

      <div class="mb-8 grid grid-cols-1 md:grid-cols-2 md:divide-x divide-gray-300">
        <div class="p-4 cursor-pointer" @click="showZoomPackshot = true">
          <img class="mx-auto max-w-sm" :src="product.image.packshot" />
          <img class="ml-auto w-6" src="/img/search-plus--grey.svg" />
        </div>
        <div class="p-4 flex flex-col justify-start items-start">
          <div>
            <div class="mb-4" v-if="product.medals">
              <img class="w-16 inline-block mx-2" v-for="(medal, key) in product.medals" :key="key" :src="medal">
            </div>
            <div class="product-body text-left text-primary" v-html="product.text.body"/>
          </div>
          <button class="py-1 px-8 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-primary text-white font-bold text mt-8 flex flex-row items-center"
            @click="shareProduct"
          >
            <span>Compartir</span> <img class="w-4 ml-3" src="/img/share--white.svg" />
          </button>
        </div>
      </div>

      <div class="flex flex-row justify-center border-b border-gray-300 text-primary">
        <div class="tab px-4 py-2 uppercase cursor-pointer" :class="{active: currTab==='ingredients'}" @click="currTab = 'ingredients'">Ingredientes</div>
        <div class="tab px-4 py-2 uppercase cursor-pointer" :class="{active: currTab==='preparation'}" @click="currTab = 'preparation'">Preparación</div>
        <div class="tab px-4 py-2 uppercase cursor-pointer" :class="{active: currTab==='table'}" @click="currTab = 'table'">Tabla Nutricional</div>
      </div>

      <div
        v-if="currTab === 'ingredients'"
        v-html="product.text.ingredients"
        class="py-8 text-justify text-primary"
      />
      <div
        v-if="currTab === 'preparation'"
        v-html="product.text.preparation"
        class="preparation py-8 text-justify text-primary"
      />

      <div v-if="currTab === 'table'" class="py-4">
        <img :src="product.image.table"/>
      </div>

    </div>

    <teleport to="#modals">
      <div v-if="showZoomPackshot"
        class="modal w-screen h-screen flex flex-col justify-center align-middle p-10"
        @click="showZoomPackshot = false"
      >
        <div class="w-full h-full bg-contain bg-center bg-no-repeat" :style="{ backgroundImage: `url(${product.image.packshot})` }"/>
      </div>
    </teleport>


  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BreastfeedingBanner from "@/components/BreastfeedingBanner.vue";
import { getProduct } from "@/providers/products";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Product",
  components: {
    "app-breastfeeding-banner": BreastfeedingBanner,
  },
  setup() {
    const route = useRoute();
    const routeSlug = route.params["slug"];

    const currTab = ref("ingredients");
    const showZoomPackshot = ref(false);

    const product = getProduct( String(routeSlug) );

    const shareProduct = () => {
      console.log(`shareProduct():`, product);
    };

    return { product, currTab, showZoomPackshot, shareProduct };
  }
});
</script>

<style scoped lang="scss">
.tab {
  &.active {
    font-weight: bold;
    border-bottom: 3px solid var(--color-primary);
    margin-bottom: -2px;
  }
}

.product-body {
  :deep(ul) {
    list-style: none;
    padding-left: 2em;

    li {
      padding-top: .5em;
      padding-bottom: .5em;
      position: relative;

      &::before {
        content: "";
        background-image: url('/img/caret-right--blue.svg');
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 1em;
        height: 1em;
        position: absolute;
        top: 0.7em;
        left: -1em;
        margin-right: 0.2rem;
      }
    }
  }
  
}

.preparation {
  text-align: center;

  :deep(.table-holder) {
    margin-bottom: 1.5em;

    .table {
      max-width: 30em;
      margin: 0 auto;
      margin-bottom: 1em;
    }

    p {
      margin-bottom: .5em;
    }
  }

  :deep(.title) {
    font-weight: 800;
    font-size: 1.5em;
    line-height: 1em;
    margin-bottom: .75em;
  }

  :deep(.emphasis) {
    font-style: italic;
    font-size: 1.125em;
    font-weight: bold;
  }

  :deep(.prep-img) {
    max-height: 5em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  :deep(.warning) {
    margin-top: 1em;
    font-weight: bold;
  }
}

.modal {
  backdrop-filter: blur(6px);
  background-color:rgba(255,255,255,.4);
}
</style>